import styled from 'styled-components';
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  PageHeader,
  Table,
  Popconfirm,
  message,
  Tabs,
  Statistic,
  InputNumber,
  Card,
  Button,
  Modal,
  Badge
} from "antd";
import FormDrawer from "../FormDrawer";
import * as R from 'ramda';

import { useParams } from "react-router-dom";
import { EditableCell, EditableRow } from "../editable";
import useRecordFiles from "../../components/useRecordFiles";
import { ConceptModel, depositsBadgeStatuses, ILedgerCheckpoint, InvoiceModel } from "../../types/models/accounting";
import RecordTable from "../RecordTable";
import { AmenityModel } from "../../types/models/housing";
import { OwnerType } from "../../types/coreModels";
import ExpenseTransactionsEditor from "./ExpenseTransactionsEditor";
import RecordSelect from "../fields/RecordSelect";
import OwnerWallet from "./OwnerWallet";
import { CheckpointAccountBalance } from "../../pages/expenses/MonthlyFinancials";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ancanaApi } from "../../api/ancanaApi";
import moment from "moment";
import { financeAmtFormat } from "../../utils";


const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .numbers-cont {
    text-align: right;
    padding-right: 16px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .ant-tabs-tab {
    padding-left: 0 !important;
  }
`;

const getColumns = (deleteRecord: any, patchRecord: any, onExpand: any, currencySymbol = '$', fractionCount = 1) => {
  const allColsSettings = { ellipsis: true }
  return [
    { title: 'Code', dataIndex: ['concept', 'code'], width: 70 },
    { title: 'Name', dataIndex: 'name', sorter: true, key: 'id' },
    {
      title: 'Property Total',
      key: 'amount',
      render: (r: any) => <div className="numbers-cont">{currencySymbol} {Number(r.entry_set[0].amount).toLocaleString()}</div>,
      width: 175,
    },
    {
      title: `${fractionCount} Fraction${fractionCount > 1 ? 's' : ''}`,
      key: 'amount',
      render: (r: any) => <div className="numbers-cont">{currencySymbol} {((Number(r.entry_set[0].amount)/8)*fractionCount).toLocaleString()}</div>,
      width: 175,
      editable: true,
    },
    // { title: 'Recurrent', dataIndex: 'name', sorter: true, key: 'id', editable: true },
    {
      title: 'Balance',
      render: (line: any) => <div className="numbers-cont">$</div>,
      width: 175
    },
    {
      title: 'Actions', width: 120, render: (line: any) => (
        <div className="row-actions">
          <a onClick={() => onExpand(line.id)}>Files</a>
          <Popconfirm
            title={`Delete this line?`}
            onConfirm={() => deleteRecord(line.id)}
            okText="Yes"
          >
            <a>Delete</a>
          </Popconfirm>
        </div>
      )
    },
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

interface OwnerExpensesProps {
  checkpoint: ILedgerCheckpoint;
  unitOwnership: OwnerType;
  accountBalances: CheckpointAccountBalance[];
}

function ExpenseEditor(props: OwnerExpensesProps) {
  const { checkpoint, unitOwnership, accountBalances } = props;
  const { id } = useParams();
  const { query } = useQuery();
  const {
    // pagination,
    handleChange,
    isLoading,
    createRecord,
    deleteRecord,
    patchRecord,
    recordList: lines,
    firstFetch
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'ledger-transactions',
    extraQueryAppend: `&ledger=${checkpoint.ledger.id}&month=${checkpoint.month}&year=${checkpoint.year}&expand=concept&account_name=Expenses&ordering=concept`,
    apiService: 'admin',
    pageSize: '100',
    initialAutoFetch: true
  });
  const elementRef = useRef<any>();
  const { tableExpandableConfig, onExpand, rowKeysMapper } = useRecordFiles('invoice-lines');
  const [ownerBalances, setOwnerBalances] = useState<any>();
  const [ownerEntries, setOwnerEntries] = useState<{ str_entries: any[], deposit_entries: any[], expense_entries: any[], initial_balance: number }>();
  const [entriesWithBalance, setEntriesWithBalance] = useState<any>([]);
  const [editingExpenses, setEditingExpenses] = useState<any>(false);
  const [editingStr, setEditingStr] = useState<any>(false);
  const checkpointDateStr = moment().year(Number(checkpoint.year)).month(Number(checkpoint.month)-1).format('MMMM YYYY');

  const fetchBalancesAndEntries = () => {
    ancanaApi.get(`unit-owners/${unitOwnership.id}/balances/`).then((res) => {
      setOwnerBalances(res.data);
    });
    ancanaApi.get(`ledger-checkpoints/${id}/owner-entries/?owner=${unitOwnership.id}`).then((res) => {
      const entriesObj = res.data;
      setOwnerEntries(entriesObj);
      const entries = [...entriesObj.deposit_entries, ...entriesObj.expense_entries]
        .sort((a, b) => a.date - b.date);
      const entriesWithBalance = entries.map((e, idx: number) => {
        const balance = entries
          .filter(e => !!e.amount)
          .slice(0, idx + 1)
          .reduce((acc: number, e) => {
            if (e.transaction_status !== 'posted') { // when the transaction is not posted, it should not affect the sum
              return acc;
            }
            if (e.type === 'deposits') {
              return acc + Number(e.amount);
            } else {
              if (e.account_name === 'Expenses') {
                return acc - (Number(e.amount)/unitOwnership.unit.fractions)*unitOwnership.fraction_numbers.length;
              } else {
                return acc - Number(e.amount);
              }
            }
          }, entriesObj?.initial_balance || 0);

        return { ...e, balance }
      });
      setEntriesWithBalance(entriesWithBalance)
    });
  }
  const subtotal = entriesWithBalance.length > 0 ? entriesWithBalance[entriesWithBalance.length - 1].balance : 0;

  useEffect(() => {
    firstFetch();
  }, [checkpoint]);

  useEffect(() => {
    // fetchBalancesAndEntries();
  }, [unitOwnership.id]);

  useEffect(() => {
    if(!editingExpenses && !editingStr) fetchBalancesAndEntries();
  }, [editingExpenses, editingStr]);

  return (
    <Container ref={elementRef}>
      <div className="disp-flex h-stack gap-sm h-center">
        <Card size="small" className="stat-card">
          <Statistic
            title={`${unitOwnership.owner.first_name}'s Reserve`}
            value={ownerBalances?.reserve.amount}
            precision={2}
            // valueStyle={{ color: '#3f8600'}}
            className="flx1"
            prefix="$"
          />
        </Card>

        <Card size="small" className="stat-card">
          <Statistic
            title={`${unitOwnership.owner.first_name}'s Balance`}
            value={ownerBalances?.expense.amount}
            precision={2}
            // valueStyle={{ color: '#3f8600'}}
            prefix="$"
          />
          <Button
            onClick={() => setEditingExpenses(true)}
            size="small" className="mt-xs" type="link" icon={<EditOutlined />}
          >
            Edit Owner Exps
          </Button>
        </Card>

        <Card size="small" className="stat-card">
          <Statistic
            title={`${unitOwnership.owner.first_name}'s STR Earnings`}
            value={ownerBalances?.str.amount}
            prefix="$"
            precision={2}
          />
          <Button
            onClick={() => setEditingStr(true)}
            size="small" className="mt-xs" type="link"
            icon={<EditOutlined />}
          >
            Edit Entries
          </Button>
        </Card>
      </div>

      <div className="right-text mr-sm">
        Initial balance:
        <b className="ml-md">${ownerEntries?.initial_balance.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}</b>
      </div>

      <Table
        size="small"
        columns={[
          // { title: 'Date', dataIndex: 'date' },
          { title: 'Concept', dataIndex: 'concept' },
          // { title: 'Type', dataIndex: 'type' },
          { title: 'Acc', dataIndex: 'account_name' },
          {
            title: 'Property Total',
            align: 'right',
            render: (r) => (r.type !== 'deposits' && r.account_name === 'Expenses') ? '$' + Number(r.amount).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }) : null,
            width: 150
          },
          {
            title: `${unitOwnership.fraction_numbers.length} Fraction${unitOwnership.fraction_numbers.length > 1 ? 's' : ''}`,
            align: 'right',
            render: (r) => {
              if (r.type === 'deposits') return null;
              const amount = r.account_name === 'Expenses' ? (r.amount / unitOwnership.unit.fractions) * unitOwnership.fraction_numbers.length : r.amount
              return '$' + Number(amount).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              });
            },
            width: 150
          },
          {
            title: 'Deposits',
            align: 'right',
            render: (r) => (
              r.type === 'deposits' ? (
                <div style={{ color: r.transaction_status === 'posted' ? 'unset' : 'gray' }}>
                  <div>{'$' + Number(r.amount).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                </div>
              ) : null
            ),
            width: 150
          },
          { title: 'T status', align: 'right', render: r => <Badge status={depositsBadgeStatuses[r.transaction_status]} text={r.transaction_status} />, width: 100 },
          { title: 'Balance', align: 'right', render: r => financeAmtFormat(r.balance), width: 150 },
        ]}
        dataSource={entriesWithBalance}
        pagination={false}
        sticky={{ offsetHeader: 40 }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1}><div className="fw-600"></div></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <div className="numbers-cont fw-600" style={{ letterSpacing: -0.5 }}>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                ${entriesWithBalance.reduce((acc: number, r: any) => {
                if (r.type === 'deposits') return acc;
                const amount = r.account_name === 'Expenses' ? (r.amount / unitOwnership.unit.fractions) * unitOwnership.fraction_numbers.length : r.amount
                return acc + Number(amount);
              }, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={2} align={'right'}>
                {financeAmtFormat(subtotal)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div>
        {financeAmtFormat(subtotal - checkpoint.cash_reserved_amount)}
      </div>

      <Modal
        title={`${unitOwnership.owner.first_name}'s Expenses for ${checkpointDateStr}`}
        visible={editingExpenses} width={1100} footer={false}
        onCancel={() => setEditingExpenses(false)}
      >
        <ExpenseTransactionsEditor
          disabled={checkpoint.status === 'closed'}
          title="Owner Expenses"
          account="Owner+Expenses"
          // extraQ={`&object_content=${unitOwnership.id}_315`}
          extraQ={`&account=${unitOwnership.expenses_account?.id}`}
          checkpoint={checkpoint}
          unitOwnership={unitOwnership}
        />
        <br />
      </Modal>

      <Modal
        title={`${unitOwnership.owner.first_name}'s STR Wallet transactions`}
        visible={editingStr} width={1100} footer={false} onCancel={() => setEditingStr(false)}
      >
        <OwnerWallet
          balance={accountBalances.find(bal => bal.account_name === 'Owner Cash')}
          checkpoint={checkpoint}
          unitOwnership={unitOwnership}
          disabled={checkpoint.status === 'closed'}
        />
        <br />
      </Modal>

    </Container>
  )
}

export default ExpenseEditor;
