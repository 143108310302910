import { RecordModel } from "../core";

export interface IContact {
  id: string;
  name: string;
  email?: string;
  phone?: string;
}

export const ContactModel: RecordModel<IContact> = {
  name: "Contacts",
  path: "contacts",
  selectQuery: "&ordering=id",
  tableQuery: "",
  toString: (r) => r.name,
  apiService: "data"
}

export interface IContactEvent {
  id: string;
  contact: number;
  source: number;
  data: any;
  object_type: "Email" | "Call" | "Form Submission" | "Page View" | "Meeting" | "Note";
  created_at: string;
}

export const ContactEventModel: RecordModel<IContactEvent> = {
  name: "Contact Events",
  path: "contact-events",
  selectQuery: "&ordering=id",
  tableQuery: "",
  toString: (r) => r.created_at,
  apiService: "data"
}
