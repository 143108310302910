import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { dataApi } from "../../api/dataApi";
import CodeView from "../../components/CodeView";
import { Card, PageHeader, Spin, Tabs, Empty, Collapse, Descriptions } from "antd";
import HubSidedContent from "../../components/HubSidedContent";
import moment from "moment/moment";
import FormDrawer from "../../components/FormDrawer";
import PageViewEvent from "../../components/crm/PageViewEvent";
import { IContactEvent } from "../../types/models/crm";

const { Panel } = Collapse;

const Container = styled.div`
  height: 100%;

  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    font-weight: 500;
    padding: 0 16px !important;
    align-items: center !important;
  }
`;

function ContactDetail() {
  const { c_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<any>(null);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState<IContactEvent[]>([]);
  const hsData = useMemo(() => {
    if (!!contact) {
      if ('properties' in contact.property_data) {
        return contact.property_data.properties;
      } else {
        return contact.property_data;
      }
    }
    return {};
  }, [contact]);

  useEffect(() => {
    setLoading(true);
    dataApi.get(`contacts/${c_id}/`).then(res => {
      setContact(res.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!!contact) {
      setLoadingEvents(true);
      dataApi.get(`contact-events/?contact=${contact.id}&page_size=500`).then(res => {
        setEvents(res.data.results);
        setLoadingEvents(false);
      });
    }
  }, [contact]);

  return (
    <Container>
      <HubSidedContent
        leftContent={<>
          <PageHeader
            onBack={() => window.history.back()}
            title={`${contact?.first_name} ${contact?.last_name}`}
            subTitle={contact?.email}
            className="pd-md"
          />
          <Descriptions
            layout="horizontal"
            column={1}
            bordered
          >
            <Descriptions.Item label="First name">{contact?.first_name}</Descriptions.Item>
            <Descriptions.Item label="Last name">{contact?.last_name}</Descriptions.Item>
            <Descriptions.Item label="Phone">{contact?.phone}</Descriptions.Item>
            <Descriptions.Item label="Interested in">{hsData.interested_in}</Descriptions.Item>
            <Descriptions.Item label="Lead status">{hsData.hs_lead_status}</Descriptions.Item>
            <Descriptions.Item label="Analytics source">{hsData.hs_analytics_source}</Descriptions.Item>
            <Descriptions.Item label="Lead source">{hsData.lead_source}</Descriptions.Item>
            <Descriptions.Item label="Latest source">{hsData.hs_latest_source_data_1}</Descriptions.Item>
            <Descriptions.Item label="Latest source 2">{hsData.hs_latest_source_data_2}</Descriptions.Item>
            <Descriptions.Item label="Modified">{moment(contact?.modified_at).format('LLL')}</Descriptions.Item>
            <Descriptions.Item label="Created">{moment(contact?.created_at).format('LLL')}</Descriptions.Item>
          </Descriptions>
        </>}
        rightContent={<>
        <Collapse
          defaultActiveKey={['1', '2', '3']}
          onChange={() => {}}
        >
          <Panel
            header={`Brokers (${!!contact?.agent ? '1': '0'})`}
            extra={(
              <FormDrawer
                recordName="contact"
                handleRecordAction={() => {}}
                btnText="+ Add"
                btnSize="small"
                btnType="link"
              />
            )}
            key="1"
          >
            {!!contact?.agent && (
            <Card>
              <a className="fw-500" href={`/brokers/${contact?.agent.id}`}>{contact?.agent.name}</a>
              <div>{contact?.agent.email}</div>
              <div>{contact?.agent.phone}</div>
            </Card>
            )}
          </Panel>
        </Collapse>
        </>}
      >
        <div className="fs-3 fw-500">Activity</div>
        <Tabs>
          <Tabs.TabPane tab="All Events" key="item-1">
            {events.map(evt => {
              if (evt.object_type === "Page View") return <PageViewEvent pageViewEvent={evt} />

              const title = `${evt.data.object_type} ${evt.data.metric}: ${evt.data.data?.subject}`;
              return evt.data.object_type === 'form' ? (
                  <Collapse className="ant-card" style={{ border: 'none', backgroundColor: 'white' }}>
                    <Panel header={title} key="1" extra={<div className="ant-card-extra">{moment(evt.created_at).format('lll')}</div>}>
                      <Descriptions
                        layout="horizontal"
                        column={1}
                        bordered
                        size="small"
                      >
                        <Descriptions.Item label="Name">{evt.data.data.form_values?.name}</Descriptions.Item>
                        <Descriptions.Item label="Email">{evt.data.data.form_values?.email}</Descriptions.Item>
                        <Descriptions.Item label="Phone">{evt.data.data.form_values?.phone}</Descriptions.Item>
                        <Descriptions.Item label="Message">{evt.data.data.form_values?.message}</Descriptions.Item>
                        <Descriptions.Item label="IP">{evt.data.data.ip_address}</Descriptions.Item>
                        <Descriptions.Item label="Agent">{evt.data.data.user_agent}</Descriptions.Item>
                      </Descriptions>
                    </Panel>
                  </Collapse>
              ) : (
                <Card
                  title={title}
                  extra={moment(evt.created_at).format('LLL')}
                />
              );
            })}
            {loadingEvents && (<div className="full-width pd-lg" style={{ textAlign: 'center' }}><Spin /></div>)}
            {!loadingEvents && !loading && events.length === 0 && (<Empty className="mt-md" />)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Inspect data" key="item-2">
            <CodeView jsonString={contact} />
          </Tabs.TabPane>
        </Tabs>
      </HubSidedContent>

      {loading && (
        <div>Loading...</div>
      )}
    </Container>
  )
}

export default ContactDetail;
