import styled from 'styled-components';
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, PageHeader, Table, Popconfirm, message, Switch, Checkbox, Form, InputNumber } from "antd";
import { DeleteOutlined, PlusOutlined, RetweetOutlined, FolderAddOutlined } from "@ant-design/icons";

import { ancanaApi } from "../../api/ancanaApi";
import { useParams } from "react-router-dom";
import { EditableCell, EditableRow } from "../editable";
import useRecordFiles from "../../components/useRecordFiles";
import { ConceptModel, ILedgerCheckpoint } from "../../types/models/accounting";
import FormDrawer from "../FormDrawer";
import RecordSelect from "../fields/RecordSelect";
import moment from "moment";
import { financeAmtFormat } from "../../utils";


const Container = styled.div`
  height: 100%;

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

const getColumns = (disabled: boolean, deleteRecord: any, doRecordAction: any, onExpand: any, currencySymbol = '$', soldFractionsRatio = 1) => {
  const allColsSettings = { ellipsis: true }
  return [
    { title: 'Code', dataIndex: ['concept', 'code'], width: 70 },
    { title: 'Name', render: (r: any) => r.name ? r.name : r.concept.name, key: 'id', editable: false },
    // { title: 'Name En', dataIndex: ['concept', 'name_en'], sorter: true, key: 'id', editable: false },
    {
      title: 'Amount',
      key: 'amount',
      // dataIndex: ['entry_set', 0, 'amount'],
      render: (r: any) => (
        <>
          {financeAmtFormat(r.amount)}
        </>
      ),
      width: 175,
      align: 'right',
      editable: true
    },
    {
      title: <RetweetOutlined title="Fixed Monthly" />,
      render: (r: any) => (
        <Checkbox
          checked={!!r.concept_default?.fixed_amount}
          onChange={(e) => {
            const payload = { amount: r.amount, is_fixed: e.target.checked };
            doRecordAction('expense-update', 'post', payload, r.id)
          }}
          disabled={disabled || !r.concept_default}
        />
      ),
      width: 40,
      align: 'center'
    },
    {
      title: 'Sold Fractions',
      key: 'amount',
      // dataIndex: ['entry_set', 0, 'amount'],
      render: (r: any) => (
        <>
          {financeAmtFormat(r.amount*soldFractionsRatio)}
        </>
      ),
      width: 150,
      align: 'right',
      editable: false
    },
    {
      title: 'Balance',
      render: (r: any) => <>
        {financeAmtFormat(r.balance)}
      </>,
      width: 150,
      align: 'right'
    },
    {
      title: 'Actions', width: 175, align: 'right', render: (line: any) => (
        <div className="row-actions" style={{ justifyContent: 'flex-end' }}>
          <a onClick={() => onExpand(line.id)}><FolderAddOutlined /> Files</a>
          <Popconfirm
            title={`Remove "${line.name}" expense?`}
            onConfirm={() => deleteRecord(line.id)}
            okText="Yes"
            placement="topRight"
            disabled={disabled}
          >
            <a style={{ color: disabled ? 'lightgray' : '' }}><DeleteOutlined /> Remove</a>
          </Popconfirm>
        </div>
      )
    },
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

interface UTEditorProps {
  checkpoint: ILedgerCheckpoint;
  account: string;
  // type: 'deposits' | 'extras';
  extraQ?: string;
  initialBalance?: number;
  title?: string;
  disabled?: boolean;
  showOwner?: boolean;
  unitOwnership?: any;
}

function ExpenseTransactionsEditor(props: UTEditorProps) {
  const { checkpoint, account, initialBalance = 0, disabled = false, extraQ = '', showOwner = false } = props;
  const { id } = useParams();
  const { query } = useQuery();
  const {
    // pagination,
    handleChange,
    isLoading,
    createRecord,
    deleteRecord,
    doRecordAction,
    recordList: transactions,
    firstFetch
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'ledger-transactions',
    extraQueryAppend: `&ledger=${checkpoint.ledger.id}&month=${checkpoint.month}&year=${checkpoint.year}&expand=concept,entry_set.account&account_name=${account}&ordering=concept${extraQ}`,
    apiService: 'admin',
    pageSize: '100',
    initialAutoFetch: true
  });
  const elementRef = useRef<any>();
  const { tableExpandableConfig, onExpand, rowKeysMapper } = useRecordFiles('ledger-transactions');

  useEffect(() => {
    firstFetch();
  }, [checkpoint]);

  const handleSave = (row: any) => {
    const payload = { amount: row.amount, is_fixed: !!row.concept_default?.fixed_amount };
    doRecordAction('expense-update', 'post', payload, row.id)
  };

  return (
    <Container ref={elementRef}>
      <PageHeader
        title={props.title || account}
        // subTitle={`${transactions.length} records`}
        extra={[
          <FormDrawer
            disabled={disabled}
            btnIcon={<PlusOutlined />}
            actionName="Add Expense"
            btnSize="small"
            recordName=""
            handleRecordAction={(data: any) => {
              const payload = {
                transaction: {
                  ledger: checkpoint.ledger.id,
                  name: '',
                  effective_at: `${checkpoint.year}-${checkpoint.month}-01`,
                  status: 'posted',
                  posted_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                  concept: data.concept
                },
                entry_set: [
                  {
                    amount: data.amount.toString(),
                    account: checkpoint.ledger.unit_cash_account,
                    direction: "credit"
                  },
                  {
                    amount: data.amount.toString(),
                    account: !!props.unitOwnership ? props.unitOwnership.expenses_account.id : checkpoint.ledger.unit_expenses_account,
                    direction: "debit",
                  }
                ]
              };
              ancanaApi.post('ledger-transactions/', payload).then(res => {
                firstFetch();
              });
            }}
          >
            <RecordSelect recordModel={ConceptModel} formName="concept" /*extraFilterQuery={`&type=deposits`}*/ />
            <Form.Item name="amount" label="Amount" >
              <InputNumber formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') } style={{ width: '100%' }} />
            </Form.Item>
          </FormDrawer>
        ]}
        className="mb-md"
      />

      <Table
        loading={isLoading}
        columns={getColumns(disabled, deleteRecord, doRecordAction, onExpand, undefined, checkpoint.fractions_sold_ratio).map(col => {
          if (!col.editable || disabled) return col;
          return {
            ...col,
            onCell: (record: any) => ({ record, editable: col.editable, dataIndex: col.dataIndex || col.key, title: col.title, handleSave })
          };
        })}
        components={{ body: { row: EditableRow, cell: EditableCell } }}
        rowClassName={() => 'editable-row'}
        dataSource={transactions
          .map((t, idx) => {
            const bal = transactions.slice(0, idx+1).reduce((acc, t) => acc - (Number(t.entry_set[0].amount)*checkpoint.fractions_sold_ratio), initialBalance);
            return { ...t, amount: t.entry_set[0].amount, balance: bal };
          })
          .map(rowKeysMapper)
        }
        pagination={false}
        // loading={isLoading}
        onChange={handleChange}
        // scroll={{ y: elementRef.current?.clientHeight - 150 }}
        size="small"
        sticky={{ offsetHeader: 32 }}
        expandable={tableExpandableConfig}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1}><div className="fw-600">Total Property Expenses</div></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <div className="numbers-cont fw-600" style={{ letterSpacing: -0.5 }}>
                  {financeAmtFormat(transactions.reduce((acc, trans) => acc + Number(trans.entry_set[0].amount), 0))}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4}>
                <div className="numbers-cont fw-600" style={{ letterSpacing: -0.5 }}>
                  {financeAmtFormat(transactions.reduce((acc, trans) => acc + Number(trans.entry_set[0].amount), 0) * checkpoint.fractions_sold_ratio)}
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </Container>
  )
}

export default ExpenseTransactionsEditor;
