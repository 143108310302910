import { RecordModel } from "../core";
import RecordSelect from "../../components/fields/RecordSelect";
import GenericRecordSelect from "../../components/fields/GenericRecordSelect";
import { OwnerModel, UnitModel } from "./housing";
import { Badge, DatePicker, Tooltip } from "antd";
import { BarChartOutlined, FileProtectOutlined, SendOutlined, WarningTwoTone, PlusCircleTwoTone, MinusCircleTwoTone, SyncOutlined } from '@ant-design/icons';
import moment from "moment/moment";
import { OwnerType } from "../coreModels";
import React from "react";
import { PresetStatusColorType } from "antd/lib/_util/colors";

export type IJournal = {
  id: number;
  owner_name: string;
  description: string;
  is_main: boolean;
  content_object: any;
}

export const JournalModel: RecordModel<IJournal> = {
  name: "Journal",
  path: "journals",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.owner_name || `Journal ${r.id}`,
}

export type IInvoice = {
  id: number;
  journal: {
    description: string;
    is_main: boolean,
    invoices: number[];
    owner_name: string;
  };
  billing_period: string;
  due_date: string;
  is_paid: boolean;
  status: string;
  total: number;
  stripe_invoice: number;
  invoiceline_set: number[];
  currency: any;
  transaction: any;
}

export type IEntry = {
  id: number;
  name: string;
  description: string;
  direction: "debit" | "credit";
  account: any;
  amount: number;
}

export type ITransaction = {
  id: number;
  ledger: number;
  name: string;
  entry_set: IEntry[];
  status: string;
  posted_at: string;
  effective_at: string;
  concept?: any;
}

export type ILedger = {
  id: number;
  name: string;
  description: string;
  transactions: ITransaction[];
  currency: string;
  object_id: number;
  content_type: number;
  unit_cash_account: number | null;
  unit_expenses_account: number | null;
  unit_revenue_account: number | null;
};

export type IAccount = {
  id: number;
  name: string;
  description: string;
  normal_balance: "debit_normal" | "credit_normal";
  ledger: number;
  owner_name: string;
}

export type IExpenseConcept = {
  id: number;
  code: string;
  name: string;
  name_es?: string;
}

export type IExpenseConceptUnitSettings = {
  id: number;
  concept: IExpenseConcept;
  unit: { id: number; name: string };
  fixed_amount: number;
  periodicity: "monthly" | "yearly" | "none";
  is_mx_invoiced: boolean;
}

export type ILedgerCheckpoint = {
  id: number;
  ledger: ILedger;
  month: string;
  year: string;
  admin_fee: string;
  status: string;
  owners: OwnerType[];
  is_missing_transactions: boolean;
  starting_balance: number;
  ending_balance: number;
  income_amount: number;
  total_expenses_amount: number;
  reserve_amount: number;
  reserve_amount_till: number;
  owner_expenses_amount: number;
  fractions_sold_expenses_amount: number;
  cash_reserved_amount: number;
  fractions_sold_ratio: number;
  prev: { id: number; month: number; year: number };
  next: { id: number; month: number; year: number };
}


const invoiceBadgeStatuses: {[status: string]: PresetStatusColorType} = {
  draft: 'default',
  open: 'processing',
  paid: 'success',
  uncollectible: 'warning',
  void: 'warning',
  overdue: 'error',
}

export const ConceptModel: RecordModel<IExpenseConcept> = {
  name: "Expense Concept",
  namePlural: "Expense Concepts",
  path: "ledger-concepts",
  selectQuery: "",
  tableQuery: "",
  toString(r): string {
    return `${r?.code}: ${r?.name}`;
  },
  customTableMappings: {
    id: { path: ['id'] }
  }
}

export const InvoiceModel: RecordModel<IInvoice> = {
  name: "Invoice",
  path: "journal-invoices",
  selectQuery: "",
  tableQuery: "&expand=journal,currency",
  toString(r): string {
    return r.journal.owner_name;
  },
  icon: <FileProtectOutlined />,
  customTableMappings: {
    journal: { path: ['journal', 'owner_name'], title: 'Owner' },
    total: { render: r => `$${(r.total/100).toLocaleString()} ${r.currency.iso}`, width: 150 },
    status: { render: r => <Badge status={invoiceBadgeStatuses[r.status]} text={r.status} />, width: 80 },
    transaction: { render: r => !!r.transaction ? r.transaction : '-', width: 60 },
    stripe_invoice: { omit: true }, "is_paid": { omit: true }, currency: { omit: true }, invoiceline_set: { omit: true },
  },
  customCreateFields: [
    { name: 'stripe_invoice', component: null },
  ],
  tableFilters: [
    { selectModel: UnitModel, autoSelectFirst: true },
    { selectModel: OwnerModel },
    { component: ({ query, handleFilter }) => (
      <DatePicker.MonthPicker
        defaultValue={!!query.get('billing_period') ? moment(query.get('billing_period')) : undefined}
        onChange={v => handleFilter('billing_period', v ? v.startOf('month').format('YYYY-MM-DD') : '')}
      />
    )},
    { selectField: 'status' },
  ],
  defaultValues: {
    due_date: moment().add(7, 'days').format("YYYY-MM-DD"),
  },
  detailAction: '/invoices',
  customAction: {
    name: 'Send',
    path: 'advance',
    isDetail: true,
    icon: <SendOutlined />,
    disabled: r => r.status !== 'draft'
  }
}

export const LedgerModel: RecordModel<ILedger> = {
  name: "Ledger",
  path: "ledgers",
  selectQuery: "&fields=id,name",
  tableQuery: "",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  detailLinkField: 'name',
  customCreateFields: [
    { name: 'object_id', component: null },
    { name: 'content_type', component: (props: any) => <GenericRecordSelect {...props} /> },
  ]
}

export const AccountModel: RecordModel<IAccount> = {
  name: "Account",
  path: "ledger-accounts",
  selectQuery: "&fields=id,name,normal_balance,owner_name",
  tableQuery: "&ordering=id&expand=ledger&fields=*,ledger.name",
  toString(r): string {
    return `${r.owner_name.split('.')[0]} ${r.name} (${r.normal_balance === 'debit_normal' ? "dn" : "cn"})`;
  },
  customCreateFields: [
    { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'object_id', component: null },
    { name: 'content_type', component: (props: any) => <GenericRecordSelect required={false} {...props} /> },
  ],
  customTableMappings: {
    normal_balance: { render: r => r.normal_balance.replace("_", " ") },
    owner_name: { omit: true },
    balances: { omit: true },
  },
  adjacentModels: [LedgerModel],
}

export const depositsBadgeStatuses: { [status: string]: PresetStatusColorType } = {
  pending: 'processing',
  posted: 'success',
  uncollectible: 'warning',
  void: 'warning',
  overdue: 'error',
}

export const EntryModel: RecordModel<IEntry> = {
  contextActions: [],
  customAction: undefined,
  customCreateFields: [],
  customTableMappings: undefined,
  name: "Entry",
  path: "ledger-entries",
  selectQuery: "",
  tableQuery: "",
  toDescriptor(e: IEntry, extraProps: any): React.ReactNode {
    return (
      <div
        className="disp-flex h-stack-strict gap-xs justify-between h-center"
        style={{ textWrap: 'balance', lineHeight: 1.2, padding: '4px 0 4px 0' } as any}
      >
        <div>{e.account.owner_name} {e.account.name}</div>
        <div style={{ textAlign: 'right', flex: 1 }}>
          <Tooltip title={e.direction}>
            <span style={{ fontWeight: 500 }}>${Number(e.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })} {' '}</span>
            {e.direction === 'debit' ? <PlusCircleTwoTone twoToneColor="limegreen" /> : <MinusCircleTwoTone twoToneColor="#d4380d" />}
          </Tooltip>
        </div>
      </div>
    );
  },
  toString(e: IEntry): string {
    return `$${Number(e.amount).toLocaleString()} ${e.account.owner_name} ${e.account.name}`;
  }
}

export const TransactionModel: RecordModel<ITransaction> = {
  name: "Transaction",
  path: "ledger-transactions",
  selectQuery: "&fields=id,status,concept.code,concept.name,effective_at,entry_set.account,entry_set.amount&expand=concept,entry_set.account",
  tableQuery: "&expand=concept,entry_set.account",
  toString(r): string {
    return `${r.id} ${r.effective_at}, ${r.concept?.code} ${r.concept?.name}: $${Number(r.entry_set[0].amount).toLocaleString()} - ${r.entry_set.map(e => e.account.owner_name + ' ' + e.account.name).join(', ')}`
  },
  toDescriptor(r) {
    return (
      <div>
        <div style={{ fontSize: 12 }} className="disp-flex h-stack-strict justify-between">
          <div><small style={{ fontFamily: 'monospace' }}>{r.id}</small> - {r.concept?.code} {r.concept?.name}</div>
          <small>{r.effective_at} <Badge style={{ marginLeft: '10%' }} status={depositsBadgeStatuses[r.status]} /></small>
        </div>
        <div>
          {r.entry_set.map(e => (<div style={{ fontSize: 10 }}>{EntryModel.toString(e)}</div>))}
        </div>
      </div>
    )
  },
  customTableMappings: {
    name: { render: r => `${r.concept?.code}: ${r.concept?.name} ${r.concept?.name !== r.name ? '"'+r.name+'"' : ''}` },
    status: { render: r => (
      <>
        <Badge status={depositsBadgeStatuses[r.status]} text={r.status} />
        {r.status === 'posted' ? <div style={{ fontSize: 11 }}>{moment(r.posted_at).format('ll')}</div> : null}
      </>
      ), width: 95
    },
    entry_set: { render: r => r.entry_set.map((e, idx) => (
        <div style={{ fontSize: '95%', borderTop: idx === 0 ? '' : '1px #dbdbdb dashed', marginRight: '6%', maxWidth: 420 }}>
          {EntryModel.toDescriptor ? EntryModel.toDescriptor(e) : EntryModel.toString(e)}
        </div>
      ))
    },
    concept: { omit: true }, ledger: { omit: true }, posted_at: { omit: true }, created_at: { omit: true },
  },
  tableFilters: [
    { selectModel: LedgerModel, autoSelectFirst: true },
    {
      component: ({ query, handleFilter }) => (
        <DatePicker.MonthPicker
          defaultValue={!!query.get('effective_month') ? moment(query.get('effective_month')) : undefined}
          onChange={v => handleFilter('effective_month', v ? v.startOf('month').format('YYYY-MM-DD') : '')}
        />
      )
    },
    { selectModel: AccountModel },
    { selectModel: ConceptModel, filter: 'concept' },
    { selectField: ['transaction', 'children', 'status'] },
  ],
  drawerWidth: 600,
  reloadOnEdit: true,
  adjacentModels: [LedgerModel, AccountModel],
}

export const ContribTransactionModel: RecordModel<ITransaction> = {
  name: "Deposit",
  path: "ledger-transactions",
  selectQuery: "&fields=id,name",
  tableQuery: "&expand=expense_concept&account_name=Owner+Contributions",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  customCreateFields: [
    // { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'account', component: (props: any) => <RecordSelect {...props} recordModel={AccountModel} allowManage /> },
  ],
  customTableMappings: {
    entries: { omit: true },
    // concept: { omit: true },
    name: { render: (r) => 'awdawdaw' }
  }
}

export const WalletTransactionModel: RecordModel<ITransaction> = {
  name: "Deposit",
  path: "ledger-transactions",
  selectQuery: "&fields=id,name",
  tableQuery: "&expand=expense_concept&account_name=Owner+Contributions",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  customCreateFields: [
    // { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'account', component: (props: any) => <RecordSelect {...props} recordModel={AccountModel} allowManage /> },
  ],
  customTableMappings: {
    entries: { omit: true },
    // concept: { omit: true },
    name: { render: (r) => 'awdawdaw' }
  }
}

export const ExpenseConceptUnitSettingsModel: RecordModel<IExpenseConceptUnitSettings> = {
  name: "Concept Default",
  path: "concept-unit-defaults",
  selectQuery: "&expand=unit,concept",
  tableQuery: "&expand=unit,concept",
  toString: (r) => `${r.concept.name} - ${r.unit.name}`,
  customCreateFields: [
    { name: 'unit', component: (props: any) => <RecordSelect {...props} recordModel={UnitModel} /> },
    { name: 'concept', component: (props: any) => <RecordSelect {...props} recordModel={ConceptModel} allowManage /> },
  ],
  // icon: <PlusOutlined />,
  customTableMappings: {
    'unit': { path: ['unit', 'name'] },
    'concept': { render: (r: any) => `${r.concept.code}: ${r.concept.name}` },
  }
}

const checkpointFieldsToHideInTable = [
  'admin_fee', 'month', 'year', 'owners', 'is_missing_transactions',
  'owner_expenses_amount', 'reserve_amount_till', 'cash_reserved_amount', 'fractions_sold_expenses_amount',
  'prev', 'next'
];

export const LedgerCheckpointModel: RecordModel<ILedgerCheckpoint> = {
  name: "House Monthly Financial",
  path: "ledger-checkpoints",
  selectQuery: "&fields=id,month,year,ledger.name&expand=ledger",
  tableQuery: "&expand=ledger",
  pageSize: 18,
  toString: (r) => `${r.month}-${r.year} ${r.ledger.name}`,
  customCreateFields: [
    { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'owners', component: null }
  ],
  detailAction: true,
  customTableMappings: {
    ledger: { title: 'Name', render: r => <>{r.ledger.name} — {moment().month(Number(r.month) - 1).format('MMMM')} {r.year}</>, width: 280 },
    status: { align: 'center', render: r => (
      <div className="disp-flex">
        <Badge status={r.status === 'open' ? 'processing' : 'success'} text={r.status} />
        <small>{r.is_missing_transactions ? <><WarningTwoTone twoToneColor="darkorange" /> Missing transactions</> : ''}</small>
      </div>
    )},
    ...Object.fromEntries(checkpointFieldsToHideInTable.map(fieldName => ([fieldName, { omit: true }]))),
  },
  customAction: {
    name: 'Recalculate Balances',
    path: 'recalculate-balances',
    icon: <SyncOutlined />
  },
  icon: <BarChartOutlined />,
  tableFilters: [
    { selectModel: LedgerModel, autoSelectFirst: true, /*extraFilterQuery: "&has_no_owner=false"*/ },
    { component: ({ query, handleFilter }: any) => (
      <DatePicker.MonthPicker
        defaultValue={(!!query.get('month') && !!query.get('year')) ? moment(`${query.get('year')}-${query.get('month')}`, "YYYY-M") : undefined}
        onChange={v => {
          handleFilter('month', v ? Number(v.get('month')) + 1 : '');
          handleFilter('year', v ? v.get('year') : '');
        }}
        format="MMM YYYY"
        allowClear={true}
      />
    )},
  ]
}

export interface IUsagePattern {
  id: number;
  created_at: string;
  modified_at: string;
  name: string;
  frequency: "Ad Hoc" | "Monthly" | "Bimonthly";
}

export const UsagePatternModel: RecordModel<IUsagePattern> = {
  name: "Usage Pattern",
  path: "usage-patterns",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.name,
}
