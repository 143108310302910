import { RecordModel } from "../core";
import { Tooltip } from "antd";
import { Button, Modal } from 'antd';
import CodeView from "../../components/CodeView";


export type Task = {
  id: number;
  name: string;
  description: string;
  last_result_successful: boolean;
}

export type TaskResult = {
  id: number;
  task: Task;
  data: any;
  modified_at: string;
  created_at: string;
  successful: boolean;
}

export const TaskModel: RecordModel<Task> = {
  name: "Task",
  path: "tasks",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.name,
  customTableMappings: {
    last_result_successful: {
      render: (task) => task.last_result_successful ? "✓" : "𐄂"
    },
    name: { render: task => (
      <a href={`task-results?task=${task.id}`}>
       {task.name}
      </a>
    )}
},
}

export const TaskResultModel: RecordModel<TaskResult> = {
  name: "Task Results",
  path: "task-results",
  selectQuery: "",
  tableQuery: '&expand=task',
  toString(r): string {
    return r.task.name;
  },
  customTableMappings: {
    data: {
      render: (task) => {
        const taskDataToString = JSON.stringify(task.data);
        const info = () => {
          Modal.info({
            title: 'Task data',
            content: (
              <CodeView jsonString={task.data} />
            ),
            onOk() {},
          });
        };
        if(taskDataToString.length > 40) {
          return (
            <Tooltip title="View more">
              <Button type="link" onClick={info}>{taskDataToString.substring(0,40) + ' ...'}</Button>
            </Tooltip>
          )
        }
        return (<div style={{padding: '4px 15px'}}>{taskDataToString}</div>);
      }

    },
    task: {
      render: (task) => task.task.name
    }
  },
  tableFilters: [ { selectModel: TaskModel }, { selectField : 'successful'} ]
}

