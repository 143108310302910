import moment from "moment";
import React from "react";
import { Collapse, Descriptions } from "antd";
import { IContactEvent } from "../../types/models/crm";


interface PageViewEventProps {
  pageViewEvent: IContactEvent;
}

function PageViewEvent(props: PageViewEventProps) {
  console.log('>>>>', props.pageViewEvent);
  const { pageViewEvent: evt } = props;

  return (
    <Collapse className="ant-card" style={{ border: 'none', backgroundColor: 'white' }}>
      <Collapse.Panel
        header={`Page View: ${evt.data?.properties?.hs_url.split('?')[0]}`}
        key={evt.id}
        extra={<div className="ant-card-extra">{moment(evt.created_at).format('lll')}</div>}
      >
        <Descriptions
          layout="horizontal"
          column={1}
          bordered
          size="small"
        >
          <Descriptions.Item label="URL"><a href={evt.data?.properties?.hs_url} target="_blank">{evt.data?.properties?.hs_url}</a></Descriptions.Item>
          <Descriptions.Item label="Browser">{evt.data?.properties?.hs_browser}</Descriptions.Item>
          <Descriptions.Item label="Location">{evt.data?.properties?.hs_city} {evt.data?.properties?.hs_region} {evt.data?.properties?.hs_country}</Descriptions.Item>
          <Descriptions.Item label="Referrer">{evt.data?.properties?.hs_referrer || '-'}</Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  )
}

export default PageViewEvent;
